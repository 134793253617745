import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {UserService} from '../../../services/user.service';
import {catchError, filter, tap} from 'rxjs/operators';
import {EMPTY} from 'rxjs';
import {ToastrNotificationService} from '../../../services/toastr-notification.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['../reset-password/reset-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  submitted: boolean = false;
  forgotPassword: FormGroup;
  username: FormControl = new FormControl('', Validators.required);
  constructor(private userService: UserService,
              private toastr: ToastrNotificationService,
              private translate: TranslateService) { }

  ngOnInit(): void {
    this.forgotPassword = new FormGroup({
      username: this.username,
    });
  }

  sendForgotPassword() {
    this.userService.sendForgotPasswordMail(this.username.value)
      .pipe(
        filter(x => x !== undefined),
        tap(() => this.submitted = true),
        catchError(err => {
          this.toastr.showErrorBasedOnStatus(err.status, this.translate.instant('translate.user.user'));
          return EMPTY;
        })
      )
      .subscribe();
  }
}
