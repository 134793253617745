<div class="d-flex justify-content-center">
  <div class="sundo-container medium text-center">
    <app-h2>{{'translate.general.welcomeUser' | translate:{'username': username} }}</app-h2>
    <app-h4>{{'translate.routes.plannedRoutes' | translate}}</app-h4>
    <ng-container *ngIf="plannedRoutes$ | async as plannedRoutes">
      <div *ngFor="let route of plannedRoutes" class="route-card d-block mt-4 mx-auto" [routerLink]="['/operations/route-overview', route.plannedRouteId]">
        <h5>
          {{route.routeDescription}}
        </h5>
      </div>

      <div *ngIf="!plannedRoutes.length">
        <h5>
          {{'translate.routes.noPlannedRoutesToday' | translate}}
        </h5>
      </div>
    </ng-container>
  </div>
</div>
