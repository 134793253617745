import {Component, OnInit, ViewChild} from '@angular/core';
import {EMPTY, Observable} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {catchError, filter, map, switchMap, tap} from 'rxjs/operators';
import {of} from 'rxjs/internal/observable/of';
import {OperationService} from '../../../services/operation.service';
import {DeviceDTO} from '../../../dtos/deviceDTO';
import {DeviceService} from '../../../services/device.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {SmotService} from '../../../services/smot.service';
import {languages} from '../../lng-picker/lng-picker.component';
import {ClientService} from '../../../services/client.service';
import {AuthenticationService} from '../../../services/authentication.service';
import {Role} from '../../../models/role';
import {ScannerService} from '../../../services/scanner.service';
import {ScannedQR} from '../../../models/ScannedQR';
import {EditImgModalComponent} from '../../plugins/edit-img-modal/edit-img-modal.component';
import {MatDialog} from '@angular/material/dialog';
import {FileUploadService} from '../../../services/file-upload.service';
import {ToastrNotificationService} from '../../../services/toastr-notification.service';
import {ValidationFormsService} from '../../forms/validation-forms/validation-forms.service';
import {UserService} from '../../../services/user.service';
import {ConfirmActionModalComponent} from '../../plugins/confirm-action-modal/confirm-action-modal.component';
import {IDropdownOption} from '../../../components/form-fields/drop-down-field/drop-down-field.component';
import {SmotDTO} from '../../../dtos/SmotDTOs/smotDTO';
import {OperationDTO} from '../../../dtos/OperationDTOs/operationDTO';
import {ClientMappings} from '../../../models/dtoMappings';
import {UserDTO} from '../../../dtos/UserDTOs/userDTO';
import {ImageCreateService} from '../../../services/image-create.service';

@Component({
  selector: 'app-create-smot',
  templateUrl: './create-smot.component.html',
  styleUrls: ['./create-smot.component.scss', '../operations.scss']
})
export class CreateSmotComponent implements OnInit {

  @ViewChild('stepper') progressStepper;

  clients$: Observable<IDropdownOption[]>;
  configs$: Observable<any[]>;
  device$: Observable<DeviceDTO>;
  deviceId: string;
  config: any;

  showScanner = false;
  showSmotConfig = false;
  showSmotForm = false;
  showSeasonScanner = false;
  showSunscreenScan = false;

  smotFormGroup: FormGroup = null;
  smotName: FormControl = new FormControl(null, [
    Validators.required,
    Validators.pattern(this.validationService.formRules.nonEmpty)]);
  smotClient: FormControl = new FormControl(null, Validators.required);
  smotLanguage: FormControl = new FormControl(null, Validators.required);
  smotStartup: FormControl = new FormControl('09:00', Validators.required);
  smotSleep: FormControl = new FormControl('20:00', Validators.required);
  smotEvent: FormControl = new FormControl(false, Validators.required);
  isOwnMaintenanceControl: FormControl = new FormControl(false);
  smotSpot: SmotDTO;

  languages$: Observable<IDropdownOption[]>;
  badScan = false;
  processingScan = false;
  disableButtons = false;

  constructor(private activatedRoute: ActivatedRoute,
              private operationService: OperationService,
              private deviceService: DeviceService,
              private smotService: SmotService,
              private clientService: ClientService,
              private authService: AuthenticationService,
              private scanService: ScannerService,
              private dialog: MatDialog,
              private fileUploadService: FileUploadService,
              private router: Router,
              private imageCreateService: ImageCreateService,
              private toastr: ToastrNotificationService,
              private validationService: ValidationFormsService,
              private userService: UserService) {
    this.activatedRoute.paramMap.subscribe(params => this.deviceId =  params.get('id'));
  }

  //region Step 1 Functions
  ngOnInit(): void {
    this.createForm();
    this.initLanguages();
    this.initConfigs();
    this.initDevice();
  }

  onSubmit(device: DeviceDTO) {
    this.smotFormGroup.markAllAsTouched();
    if (this.smotFormGroup.invalid) { return; }
    this.disableButtons = true;
    this.createSmot(device).pipe(
      tap(() => {
        this.showSeasonScanner = true;
        this.showSmotForm = false;
        this.progressStepper.continue();
        this.disableButtons = false;
      })
    ).subscribe();
  }

  goToClearForm() {
    this.config = null;
    this.showSmotConfig = false;
    this.showSmotForm = true;
  }

  goToConfigForm() {
    this.showSmotConfig = false;
    this.showSmotForm = true;
    const configuration: SmotDTO = this.config;
    this.smotName.setValue(configuration.locationDescription);
    this.smotLanguage.setValue(configuration.language);
    this.smotStartup.setValue(configuration.wakeUpTime.toString() + ':00');
    this.smotSleep.setValue(configuration.sleepTime.toString() + ':00');
    this.smotClient.setValue(configuration.client.clientNumber.toString());
    this.smotEvent.setValue(configuration.isEvent);
  }

  truncateString(string: String, limit: number): String {
    return string.split('', limit).reduce((o, c) => o.length === (limit - 1) ? `${o}${c}...` : `${o}${c}`, '');
  }

  disassembleDevice(device: DeviceDTO) {
    this.deviceService.disassembleDevice(device.deviceId.toString()).pipe(
      filter(x => x !== undefined),
      tap(() => this.router.navigate(['operations']))
    ).subscribe();
  }

  changeCasing(device: DeviceDTO) {
    device.casingId = 0;
    this.showScanner = true;
  }

  changeBackplate(device: DeviceDTO) {
    device.backplate.backplateId = 0;
    this.showScanner = true;
  }

  scanCompleteHandler(event, device: DeviceDTO) {
    this.badScan = false;
    if (event === undefined) { this.badScan = true; return; }
    const scannedQR: ScannedQR = this.scanService.verifyScan(event);
    if (scannedQR == null) { this.badScan = true; return; }

    if (scannedQR.type === 'backplate' && device.backplate.backplateId === 0) {
      this.checkBackplateAvailability(scannedQR.backplateId, device);
    } else if (scannedQR.type === 'casing' && device.casingId === 0) {
      this.checkCasingAvailability(scannedQR.casingId, device);
    } else {
      this.badScan = true;
    }
  }

  private checkCasingAvailability(id, device: DeviceDTO) {
    return this.deviceService.getDeviceByCasing(id).pipe(
      filter(x => x !== undefined),
      tap(result => {
        if (result === null) {
          device.casingId = +id;
          if (device.backplate.backplateId !== 0) {
            this.showScanner = false;
          }
        } else {
          this.showConfirmActionModal('translate.operations.create-device.casingIsAlreadyUsedInAnotherDeviceDoYouWishToMoveItToThisDevice').pipe(
            filter(x => x !== undefined),
            tap(userResponse => {
              if (userResponse === true) {
                device.casingId = +id;
                if (device.backplate.backplateId !== 0) {
                  this.showScanner = false;
                }
              }
            })
          ).subscribe();
        }
      })
    ).subscribe();
  }


  private checkBackplateAvailability(id, device: DeviceDTO) {
    return this.deviceService.getDeviceByBackplate(id).pipe(
      filter(x => x !== undefined),
      tap(result => {
        if (result === null) {
          device.backplate.backplateId = +id;
          this.showScanner = false;
        } else {
          this.showConfirmActionModal('translate.operations.create-device.backplateIsAlreadyUsedInAnotherDeviceDoYouWishToMoveItToThisDevice').pipe(
            filter(x => x !== undefined),
            tap(userResponse => {
              if (userResponse === true) {
                device.backplate.backplateId = +id;
                this.showScanner = false;
              }
            })
          ).subscribe();
        }
      })
    ).subscribe();
  }

  private showConfirmActionModal(message: String): Observable<any> {
    const dialog = this.dialog.open(ConfirmActionModalComponent, {
      data: {
        key: message
      }
    });
    return dialog.afterClosed();
  }

  private initLanguages() {
    const tempLngs: IDropdownOption[] = [];
    languages.forEach(l => {
      tempLngs.push({
        label: l.language,
        value: l.fileName
      });
    });
    this.languages$ = of(tempLngs);
  }

  private initDevice() {
    this.device$ = this.operationService.operationDevice$.pipe(
      switchMap(device => {
        if (device !== undefined) { return of(device); }
        return this.deviceService.getDeviceById(+this.deviceId);
      })
    );
  }

  private initConfigs() {
    this.configs$ = this.smotService.getDeinstalledSmots();
  }

  private initClients() {
    this.clientService.getClients(ClientMappings.DROPDOWN_CLIENT_DTO).pipe(
      filter(x => x !== undefined),
      map(clients => clients.map(client =>  ({
        label: client.clientName,
        value: client.clientNumber.toString()
      }))),
      tap(clients => this.clients$ = of(clients))
    ).subscribe();
  }

  private createForm() {
    this.smotFormGroup = new FormGroup({
      name: this.smotName,
      language: this.smotLanguage,
      startupTime: this.smotStartup,
      sleepTime: this.smotSleep,
      event: this.smotEvent,
      isOwnMaintenance: this.isOwnMaintenanceControl
    });
    // Check if user is partner, admin or staff for partner or admin and add Client Control
    this.userService.getUser<UserDTO>(this.authService.getUsername()).pipe(
      filter(x => x !== undefined),
      tap(user => {
        const roles = [Role.STAFF, Role.ADMIN, Role.PARTNER];
        if (roles.includes(<Role>this.authService.getRole())) {
          if (user.client === null) {
              this.smotFormGroup.addControl('client', this.smotClient);
              this.initClients();
          }
        }
      })
    ).subscribe();
  }

  private createSmot(device: DeviceDTO): Observable<SmotDTO> {
    if (this.smotFormGroup.contains('client')) {
      return this.clientService.getClient(this.smotClient.value).pipe(
        filter(x => x !== undefined),
        map(client => ({
          device: device,
          locationDescription: this.smotName.value,
          wakeUpTime: Number(this.smotStartup.value.toString().split(':')[0]),
          sleepTime: Number(this.smotSleep.value.toString().split(':')[0]),
          isEvent: +this.smotEvent.value,
          isOwnMaintenance: +this.isOwnMaintenanceControl.value,
          language: this.smotLanguage.value,
          client: client,
          seasonId: null
        }) as SmotDTO),
        tap(smot => this.smotSpot = smot)
      );
    } else {
      return this.userService.getUser<UserDTO>(this.authService.getUsername()).pipe(
        filter(x => x !== undefined),
        map(user => ({
          device: device,
          locationDescription: this.smotName.value,
          wakeUpTime: Number(this.smotStartup.value.toString().split(':')[0]),
          sleepTime: Number(this.smotSleep.value.toString().split(':')[0]),
          isEvent: +this.smotEvent.value,
          language: this.smotLanguage.value,
          client: user.client,
          isOwnMaintenance: +this.isOwnMaintenanceControl.value,
          seasonId: null
        }) as SmotDTO),
        tap(smot => this.smotSpot = smot)
      );
    }

  }
  //endregion

  //region Step 2 Functions
  scanSeasonIdCompleteHandler(event) {
    if (this.processingScan) { return; }
    this.badScan = false;
    if (event === undefined) { return; }
    const scannedQR: ScannedQR = this.scanService.verifyScan(event);
    if (scannedQR === null || scannedQR.type !== 'season') { this.badScan = true; return; }

    this.processingScan = true;
    this.smotService.checkSeasonIdAvailability(scannedQR.seasonId).pipe(
      filter(x => x !== undefined),
      tap(result => {
        if (result) {
          this.smotSpot.seasonId = +scannedQR.seasonId;
          this.saveSmot();
          this.processingScan = false;
        } else {
          this.toastr.showError('SeasonId has already been taken', 'SeasonId not unique');
          this.processingScan = false;
        }
      }),
      catchError(err => {
        this.toastr.showErrorBasedOnStatus(err.status);
        this.processingScan = false;
        return EMPTY;
      })
    ).subscribe();
  }

  private saveSmot() {
    this.smotService.createSmot(this.smotSpot).pipe(
      filter(x => x !== undefined),
      tap(smot => this.smotSpot = smot),
      tap(() => this.progressStepper.continue()),
      tap(() => this.toastr.showSucces('SMOTSpot Successfully created', 'Success'))
    ).subscribe();
  }
  //endregion

  //region Step 3 Functions

  activateSmot() {
    this.smotService.activateSmot(this.smotSpot).pipe(
      filter(x => x !== undefined),
      tap(() => this.progressStepper.continue())
    ).subscribe();
  }
  //endregion

  //region Step 4 Functions
  scanSunscreenCompleteHandler(event) {
    if (event === undefined) { return; }
    this.badScan = false;
    const scannedQR: ScannedQR = this.scanService.verifyScan(event);
    if (scannedQR === null || scannedQR.type !== 'sunscreen') { this.badScan = true; return; }

    const operation: OperationDTO = this.createRefillOperation(scannedQR);
    this.operationService.postOperation(operation).pipe(
      filter(x => x !== undefined),
      tap(() => this.progressStepper.continue()),
      tap(() => this.toastr.showSucces('Sunscreen Successfully replaced', 'Succes'))
    ).subscribe();
  }

  private createRefillOperation(scannedQR: ScannedQR): OperationDTO {
    return {
      operationType: 'REFILL',
      smot: this.smotSpot,
      info: JSON.stringify({
        newPackageId: +scannedQR.sunscreenId,
        previousPackageId: this.smotSpot?.packaging?.packageId
      })
    };
  }
  //endregion

  //region Step 5 Functions
  onFileSelect(event) {
    const smotImg = event.target.files[0];
    const reader = new FileReader();
    const _this = this;
    reader.addEventListener('load', function () {
      // convert image to base64 encoded string
      _this.openDialog(this.result);
    });
    reader.readAsDataURL(smotImg);
  }

  private openDialog(imgToCrop) {
    const dialog = this.dialog.open(EditImgModalComponent, {
      data: {
        imageSource: imgToCrop
      }
    });

    dialog.afterClosed().pipe(
        filter(x => x !== undefined),
        tap(result => this.saveSmotImg(result)),
      ).subscribe();
  }

  private saveSmotImg(result) {
    this.imageCreateService.urltoFile(result, 'temp.png', 'image/png')
      .then((file) => {
        const imgName = this.smotSpot.logicalId + '.png';
        this.fileUploadService.uploadSmotProfilePicture(file, imgName, this.smotSpot)
          .pipe(
            filter(x => x !== undefined),
            tap(() => this.router.navigate(['/operations'])),
            tap(() => this.toastr.showSucces('Image Succesfully Uploaded', 'Success'))
          )
          .subscribe();
      });
  }
  //endregion
}
