import {Injectable} from '@angular/core';
import {ApiHelperService} from './api-helper.service';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';
import {DeviceDTO} from '../dtos/deviceDTO';
import {Sort} from '@angular/material/sort';
import {SmotDTO} from '../dtos/SmotDTOs/smotDTO';
import {DropdownSmotDTO} from '../dtos/SmotDTOs/dropdownSmotDTO';
import {OperationDTO} from '../dtos/OperationDTOs/operationDTO';
import {HeatmapDataDTO} from '../dtos/SmotDTOs/heatmapDataDTO';
import {ActionDTO} from '../dtos/OperationDTOs/actionDTO';
import {SmotMappings} from '../models/dtoMappings';

@Injectable({
  providedIn: 'root'
})
export class SmotService {

  private smotUrl = environment.apiRoot + '/smots';
  private operationSmot: SmotDTO;

  constructor(private helper: ApiHelperService) {
  }

  /**
   * Gets the list of sunscreens asynchronously. The request is only sent
   * once you `subscribe()` to the returned `Observable`.
   */
  getSmots<T>(mappingTarget: SmotMappings = SmotMappings.DEFAULT): Observable<T[]> {
    const url = this.smotUrl + '?mappingTarget=' + mappingTarget;
    return this.helper.getRequest(url);
  }

  getSmotsFromProducer(producer_id: number): Observable<SmotDTO[]> {
    return this.helper.getRequest<SmotDTO[]>(this.smotUrl + '/producer/' + producer_id);

  }
  getSmotStatusOverview(): Observable<Map<string, number>> {
    const statusUrl = this.smotUrl + '/status/overview';
    return this.helper.getRequest<Map<string, number>>(statusUrl);
  }

  getSmotsWithpagination(page: number | null = null, size: number | null = null, search: string = '', sort: Sort = null, showDeinstalled: boolean = false,  mappingTarget: SmotMappings = SmotMappings.DEFAULT) {
    let url = this.smotUrl;
    url += this.helper.getPaginationString(page, size, search);
    url += this.helper.getSortString(sort);
    url += '&showDeinstalled=' + showDeinstalled;
    url += '&mappingTarget=' + mappingTarget;
    return this.helper.getRequest(url);
  }


  getSmotsWithStatus(status: string): Observable<SmotDTO[]> {
    const statusUrl = this.smotUrl + '/status/' + status;
    return this.helper.getRequest<SmotDTO[]>(statusUrl);
  }

  getSmotsWithStatusWithPagination(statuses: String[], page: number | null = null, size: number | null = null, search: string = '', sort: Sort = null, mappingTarget: SmotMappings = SmotMappings.DEFAULT): Observable<SmotDTO[]> {
    let url = this.smotUrl + '/status';

    url += this.helper.getPaginationString(page, size, search);
    url += this.helper.getSortString(sort);
    url += '&statuses=';
    for (let i = 0; i < statuses.length; i++) {
      url += statuses[i];
      if (i < statuses.length - 1) {
        url += ',';
      }
    }
    return this.helper.getRequest(url);
  }

  getSmotsByClient(clientNumber: number, mappingTarget: SmotMappings = SmotMappings.DEFAULT): Observable<SmotDTO[] | DropdownSmotDTO[]> {
    let url = this.smotUrl + '/client/' + clientNumber;
    if (mappingTarget) {
      url += '?mappingTarget=' + mappingTarget;
    }
    return this.helper.getRequest(url);
  }
  getSmotsByPartner(partnerId: number): Observable<SmotDTO[]> {
    const url = this.smotUrl + '/partner/' + partnerId;
    return  this.helper.getRequest<SmotDTO[]>(url);
  }

  getSmotsByProducer(producerId: number): Observable<SmotDTO[]> {
    const url = this.smotUrl + '/producer/' + producerId;
    return  this.helper.getRequest<SmotDTO[]>(url);
  }

  getSmot(id: number): Observable<SmotDTO> {
    const url = this.smotUrl + '/' + id;
    return this.helper.getRequest<SmotDTO>(url);
  }

  getSmotLastOperation(id: number, preventDefaulBehavior: boolean = false): Observable<OperationDTO> {
    const url = this.smotUrl + `/${id}/lastOperation`;
    return this.helper.getRequest<OperationDTO>(url, null, preventDefaulBehavior);
  }
  getDeinstalledSmots() {
    const url = this.smotUrl + '/configurations';
    return this.helper.getRequest<SmotDTO[]>(url);
  }

  replaceSunscreen(sunscreenId: string ): Observable<SmotDTO> {
    const url = this.smotUrl + '/' + this.getOperationSmot().logicalId + '/changePackaging';
    return this.helper.putRequest<SmotDTO>(url, sunscreenId);
  }

  getSmotByDevice(deviceId: number): Observable<SmotDTO[]> {
    const url = this.smotUrl + '/iccid/' + deviceId;
    return this.helper.getRequest<SmotDTO[]>(url);
  }

  saveSmot(smot: SmotDTO): Observable<SmotDTO> {
    return this.helper.putRequest(this.smotUrl, smot);
  }

  createSmot(smot: SmotDTO): Observable<SmotDTO> {
    return this.helper.postRequest(this.smotUrl, smot);
  }

  getSmotFromCasingOrBackplateOrSeasonId(casingId: string | number, backplateId: string | number, seasonId: string | number = null, preventErrorBehavior: boolean = false): Observable<SmotDTO | DeviceDTO> {
    let requestUrl = this.smotUrl + '/device/find?';
    if (casingId) {
      requestUrl += 'casingId=' + Number(casingId);
    }
    if (backplateId) {
      if (casingId) {
        requestUrl += '&';
      }
      requestUrl += 'backplateId=' + Number(backplateId);
    }
    if (seasonId) {
      if (casingId || backplateId) {
        requestUrl += '&';
      }
      requestUrl += 'seasonId=' + Number(seasonId);
    }

    return this.helper.getRequest<SmotDTO | DeviceDTO>(requestUrl, null, preventErrorBehavior);
  }

  getHeatmapData(): Observable<HeatmapDataDTO[]>  {
    const url = this.smotUrl + '/heatmap';
    return this.helper.getRequest(url);
  }

  setOperationSmot(smot: SmotDTO) {
    this.operationSmot = smot;
  }

  getOperationSmot() {
    return this.operationSmot;
  }

  activateSmot(smot: SmotDTO): Observable<SmotDTO> {
    const smotUrl = this.smotUrl + '/activate';
    return this.helper.postRequest<SmotDTO>(smotUrl, smot);
  }

  deactivateSmot(smot: SmotDTO): Observable<SmotDTO> {
    const smotUrl = this.smotUrl + '/deactivate';
    return this.helper.postRequest<SmotDTO>(smotUrl, smot);
  }

  instanceOfSmot(obj: any) {
    return 'logicalId' in obj && 'client' in obj;
  }

  fetchSmotImage(logicalId: string): Observable<Blob> {
    const url = environment.apiRoot + '/fileUpload/smot/' + logicalId;
    return this.helper.getRequest<Blob>(url, {responseType: 'blob'});
  }

  performSmotAction(params: ActionDTO): Observable<any> {
    const url = environment.apiRoot + '/smots/action-override';
    return this.helper.putRequest(url, params);
  }

  performEnvironmentSwitch(params): Observable<any> {
    const url = environment.apiRoot + '/smots/environment-switch';
    return this.helper.putRequest(url, params);
  }

  checkSeasonIdAvailability(seasonId): Observable<boolean> {
    const url = environment.apiRoot + '/smots/checkSeasonIdAvailability/' + seasonId;
    return this.helper.getRequest(url, null, true);

  }
}
