<div class="d-flex flex-row justify-content-center align-items-center" style="height: 50vh;">
  <div class="card">
    <div class="card-header">
      {{'translate.settings.changePassword.changePassword' | translate }}
    </div>
    <div class="card-body">
      <app-h4 class="text-center mb-3">{{'translate.settings.changePassword.enterNewPassword' | translate}}:</app-h4>
      <div class="d-flex justify-content-center">
        <form [formGroup]="form" (ngSubmit)="changePassword();">
          <div class="w-100">
            <mat-form-field appearance="fill" >
              <mat-label>{{'translate.settings.changePassword.newPassword' | translate }}</mat-label>
              <input type="password" #passwordInput placeholder="{{'translate.settings.changePassword.newPassword' | translate }}" matInput [formControl]="password">
            </mat-form-field>
            <div *ngIf="password.touched && password.invalid" class="error-message-minimal" style="transform: translateY(-18px)">
              <div *ngIf="password.errors.required">{{'translate.form.isRequired' | translate:{'item':passwordInput.placeholder } }}</div>
            </div>
          </div>

          <mat-form-field appearance="fill" >
            <mat-label>{{'translate.settings.changePassword.confirmNewPassword' | translate }}</mat-label>
            <input type="password" matInput [formControl]="confirmPassword">
          </mat-form-field>
          <div *ngIf="(confirmPassword.touched && confirmPassword.invalid) || (confirmPassword.value !== password.value && confirmPassword.dirty && confirmPassword.touched)"  style="transform: translateY(-18px)">
            <div class="error-message-minimal" *ngIf="confirmPassword.errors?.required">{{'translate.form.isRequired' | translate:{'item':passwordInput.placeholder } }}</div>
            <div class="error-message-minimal" *ngIf="confirmPassword.value !== password.value">{{'translate.settings.changePassword.passwordsDoNotMatch' | translate}}</div>
          </div>
          <div class="d-flex justify-content-center">
            <app-primary-button [type]="'submit'" [disabled]="!form.valid">{{'translate.form.submit' | translate}}</app-primary-button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>


