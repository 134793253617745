import {Component, Input, OnInit} from '@angular/core';
import {BaseRouteStepComponent} from '../base-route-step/base-route-step.component';
import {FormControl, Validators} from '@angular/forms';
import {ValidationFormsService} from '../../../forms/validation-forms/validation-forms.service';
import {RouteFlowStepData} from '../../../../models/routeFlows';
import {RouteService} from '../../../../services/route.service';
import {catchError, filter, tap} from 'rxjs/operators';
import {Router} from '@angular/router';
import {EMPTY} from 'rxjs';
import {ToastrNotificationService} from '../../../../services/toastr-notification.service';
import {RouteStepCommentDTO} from '../../../../dtos/RouteDTOs/routeDTO';
import {CommentType} from '../../../../models/commentType';
import {ResolutionStatus} from '../../../../models/resolutionStatus';
import {TranslateService} from '@ngx-translate/core';
import {RouteMappings} from '../../../../models/dtoMappings';

@Component({
  selector: 'app-finish-route',
  templateUrl: './finish-route.component.html',
  styleUrls: ['./finish-route.component.scss', '../../operations.scss']
})
export class FinishRouteComponent implements OnInit, BaseRouteStepComponent {
  @Input() data: RouteFlowStepData;
  commentFormControl: FormControl = new FormControl('', [Validators.required, Validators.pattern(this.vf.formRules.nonEmpty)]);

  constructor(private vf: ValidationFormsService,
              private routeService: RouteService,
              private router: Router,
              private toastr: ToastrNotificationService,
              private translate: TranslateService) {
  }

  ngOnInit(): void {
  }

  public previousStep() {
    this.routeService.emitOnStepReturn();
  }

  public finishStep() {
    const stepIndex = this.data.plannedRoute.plannedRouteSteps.findIndex(s => +this.data.stepId === +s.routeStep.routeStepId);
    this.data.plannedRoute.plannedRouteSteps[stepIndex].finishTime = new Date();
    this.routeService.updatePlannedRoute(this.data.plannedRoute).pipe(
      filter(x => x !== undefined),
      tap(() => this.routeService.clearStepStage()),
      tap(() => this.router.navigate(['operations/route-overview', this.data.plannedRoute.plannedRouteId])),
      catchError(err => {
        this.toastr.showErrorBasedOnStatus(err.status);
        return EMPTY;
      })
    ).subscribe();
  }

  public postComment() {
    if (this.commentFormControl.invalid) {
      return;
    }

    const stepIndex = this.data?.plannedRoute?.route?.steps?.findIndex(s => +s.routeStepId === +this.data.stepId);
    if (stepIndex === -1 || stepIndex === undefined) {
      this.toastr.showErrorBasedOnStatus(500);
      return;
    }

    const smot = this.data.plannedRoute.route.steps[stepIndex].smot;
    const comment = this.createComment(smot);

    if (smot) {
      this.data.plannedRoute.route.steps[stepIndex].smot.routeStepComments.push(comment);
    } else {
      this.data.plannedRoute.route.steps[stepIndex].routeStepComments.push(comment);
    }

    this.routeService.updateRoute(this.data.plannedRoute.route, RouteMappings.DEFAULT, 'true').pipe(
      filter(x => x !== undefined),
      tap(() => {
        this.commentFormControl.reset();
        this.toastr.showSucces(this.translate.instant('translate.routes.addCommentSuccessful'), 'Success');
      }),
      catchError(err => {
        this.toastr.showErrorBasedOnStatus(err.status);
        return EMPTY;
      })
    ).subscribe();
  }

  private createComment(smot): RouteStepCommentDTO {
    return {
      type: CommentType.COMMENT,
      content: this.commentFormControl.value,
      resolutionStatus: ResolutionStatus.UNRESOLVED,
      creationDate: new Date(),
      routeStepId: smot ? null : +this.data.stepId,
      resolveDate: null,
      smotLogicalId: smot ? smot.logicalId : null,
      routeStepCommentId: null,
    };
  }
}
