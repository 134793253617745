import {Component, OnInit} from '@angular/core';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {UserService} from '../../services/user.service';
import {AuthenticationService} from '../../services/authentication.service';
import {filter, switchMap, tap} from 'rxjs/operators';
import {UserDTO} from '../../dtos/UserDTOs/userDTO';
import {UserMappings} from '../../models/dtoMappings';
import {UserAuthenticationDTO} from '../../dtos/UserDTOs/userAuthenticationDTO';

@Component({
  selector: 'app-lng-picker',
  template: `
    <div class="language-picker">
      <mat-form-field style="width: 50px; margin-right: 20px">
        <span class="flag-icon flag-icon-{{flagCode}} picker-flag"></span>
        <mat-select [value]="currentLang" (selectionChange)="changeLanguage($event)">
          <mat-option [disabled]="true"></mat-option>
          <mat-option *ngFor="let lng of languages" [value]="lng.fileName">
            <span class="flag-icon flag-icon-{{lng.flagCode}}"></span> {{lng.shortLang}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  `,
  styles: [`.picker-flag {
    position: absolute;
    left: -25px;
    bottom: 6px;
  }`]
})
export class LngPickerComponent implements OnInit {

  constructor(private translateService: TranslateService,
              private authService: AuthenticationService,
              private userService: UserService) {
  }

  user: UserAuthenticationDTO;
  currentLang: string;
  languages = languages;
  flagCode: string;

  ngOnInit(): void {
    this.translateService.onLangChange.pipe(
      filter(x => x !== undefined),
      tap((event: LangChangeEvent) => {
        this.currentLang = event.lang;
        this.flagCode = this.languages.find(l => l.fileName === event.lang).flagCode;
      })
    ).subscribe();


    this.userService.getUser<UserAuthenticationDTO>(this.authService.getUsername(), false, UserMappings.USER_AUTHENTICATION_DTO).pipe(
      filter(x => x !== undefined),
      tap(user => {
        this.currentLang = user.language;
        this.flagCode = this.languages.find(l => l.fileName === user.language).flagCode;
        this.user = user;
      })
    ).subscribe();
  }

  public changeLanguage(event: any) {
    if (!event.value) {
      return;
    }
    this.translateService.use(event.value);
    this.userService.getUser<UserDTO>(this.user.username).pipe(
      switchMap(user => {
        user.language = event.value;
        return this.userService.editUser(user).pipe(
        filter(x => x !== undefined),
        tap(() => {
          this.currentLang = event.value;
          this.flagCode = this.languages.find(l => l.fileName === event.value).flagCode;
        })
        );
      })
    ).subscribe();
  }
}


export const languages = [
  {
    language: 'English',
    fileName: 'EN',
    flagCode: 'gb',
    shortLang: 'en'
  },
  {
    language: '(België) - Nederlands',
    fileName: 'BENL',
    flagCode: 'be',
    shortLang: 'nl'
  },
  {
    language: '(Belgique) - Français',
    fileName: 'BEFR',
    flagCode: 'be',
    shortLang: 'fr'
  },
  {
    language: 'Nederlands',
    fileName: 'NL',
    flagCode: 'nl',
    shortLang: 'nl'
  },
  {
    language: 'Italiano',
    fileName: 'IT',
    flagCode: 'it',
    shortLang: 'it'
  },
  {
    language: 'Italiano',
    fileName: 'DUI',
    flagCode: 'de',
    shortLang: 'de'
  },
];
