<video #videoElement
  [class.invalid]="badScan"
></video>
<app-input-field
  [class.invalid]="badScan"
  [hideLabel]="true"
  [placeholder]="'QR Code'"
  [emitOnLosingFocus]="true"
  (enteredInput)="emitScan($event)"
></app-input-field>
<p class="error-message-minimal text-center" *ngIf="badScan">
  {{ 'translate.operations.shared.badQRCodeScanned' | translate }}
</p>
