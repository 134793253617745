import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-success-button',
  template: `
    <button class="success"
            [class.small-button]="size === 'small'"
            [class.big-button]="size === 'big'"
            [class.disabled]="disabled"
            [class.w-100]="fullWidth"
            [disabled]="disabled"
            [type]="type">
        <ng-content></ng-content>
    </button>
  `,
  styleUrls: ['../button-styles.scss'],
})
export class SuccessButtonComponent implements OnInit {
  @Input() size: 'small' | 'normal' | 'big' = 'normal';
  @Input() fullWidth: boolean = false;
  @Input() disabled: boolean = false;
  @Input() type: 'button' | 'submit' | 'reset' = 'button';

  constructor() { }

  ngOnInit(): void {
  }

}
