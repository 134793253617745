import {Component, OnInit} from '@angular/core';
import {ScannedQR} from '../../../models/ScannedQR';
import {ScannerService} from '../../../services/scanner.service';
import {OperationService} from '../../../services/operation.service';
import {catchError, filter, map, tap} from 'rxjs/operators';
import {DeviceService} from '../../../services/device.service';
import {DeviceDTO} from '../../../dtos/deviceDTO';
import {SmotService} from '../../../services/smot.service';
import {Router} from '@angular/router';
import {SmotDTO} from '../../../dtos/SmotDTOs/smotDTO';
import {TablePlannedRouteDTO} from '../../../dtos/RouteDTOs/tablePlannedRouteDTO';
import {PlannedRouteMappings} from '../../../models/dtoMappings';
import moment from 'moment';
import {EMPTY} from 'rxjs';
import {RouteService} from '../../../services/route.service';
import {AuthenticationService} from '../../../services/authentication.service';
import {ToastrNotificationService} from '../../../services/toastr-notification.service';

@Component({
  selector: 'app-smot-scanner',
  templateUrl: './smot-scanner.component.html',
  styleUrls: ['../operations.scss', '../smot-operation/smot-operation.component.scss']
})
export class SmotScannerComponent implements OnInit {

  constructor(private scanService: ScannerService,
              private operationService: OperationService,
              private deviceService: DeviceService,
              private smotService: SmotService,
              private router: Router,
              private toastr: ToastrNotificationService,
              private routeService: RouteService,
              private authService: AuthenticationService) { }

  badScan: boolean = false;

  showVideos: boolean = false;
  displayRoutesButton: boolean = false;

  ngOnInit(): void {
    this.fetchPlannedRoutes();
  }

  scanCompleteHandler(event) {
    this.badScan = false;

    if (event === undefined) { this.badScan = true; return; }
    const scannedQR: ScannedQR = this.scanService.verifyScan(event);
    if (scannedQR == null) { this.badScan = true; return; }
    const device = this.createBlankDevice(+scannedQR.casingId, +scannedQR.backplateId);

    this.operationService.operationEntityScanned(+scannedQR.casingId, +scannedQR.backplateId, +scannedQR.seasonId).pipe(
      tap(value => {
        if (value == null) {
          this.startCreateDeviceOperationFlow(device);
          return;
        }

        if (this.deviceService.instanceOfDevice(value)) {
            this.startCreateSmotOperationFlow(value);
            return;
        }

        if (this.smotService.instanceOfSmot(value)) {
          this.startSmotOperationFlow(value);
          return;
        }
      }),
    ).subscribe();
  }

  private startSmotOperationFlow(smot: SmotDTO) {
    this.operationService.operationSmotChanged(smot);
    this.router.navigate(['operations/smot/' + smot.logicalId]);
  }

  private startCreateDeviceOperationFlow(device) {
    this.operationService.operationDeviceChanged(device);
    this.router.navigate(['operations/device/create-device']);
  }

  private startCreateSmotOperationFlow(device: DeviceDTO) {
    this.operationService.operationDeviceChanged(device);
    this.router.navigate([`operations/device/${device.deviceId}/create-smot`]);
  }

  private createBlankDevice(casingId: number, backplateId: number): DeviceDTO {
    return {
      casingId: casingId,
      backplate: {
        backplateId: backplateId,
        iccid: null,
        version: null,
        state: null,
        type: null
      }
    };
  }

  private fetchPlannedRoutes() {
    this.routeService.getPlannedRoutes<TablePlannedRouteDTO[]>(PlannedRouteMappings.TABLE_PLANNED_ROUTE_DTO, moment().startOf('day').unix(), this.authService.getUsername()).pipe(
      filter(x => x !== undefined),
      map(routes => routes.filter(r => !r.finishTime)),
      tap((routes: TablePlannedRouteDTO[]) => this.displayRoutesButton = !!routes.length),
      catchError(err => {
        this.toastr.showErrorBasedOnStatus(err.status);
        return EMPTY;
      })
    ).subscribe();
  }
}
