import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {JsQrScannerComponent} from './js-qr-scanner/js-qr-scanner.component';
import {FormFieldsModule} from '../form-fields/form-fields.module';
import {TranslateModule} from '@ngx-translate/core';


@NgModule({
  declarations: [
    JsQrScannerComponent
  ],
  exports: [
    JsQrScannerComponent
  ],
    imports: [
        CommonModule,
        FormFieldsModule,
        TranslateModule
    ]
})
export class ScannerModule { }
