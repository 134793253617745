<div class="sundo-container medium h-100 m-auto position-relative">
  <div class="back-button" (click)="previousStep()">
    <mat-icon >keyboard_arrow_left</mat-icon>
  </div>
  <app-h2 class="text-center">{{data.headerString | translate}}</app-h2>
  <div class="image" *ngIf="!previewUrl"></div>
  <div class="d-flex justify-content-center">
    <img [src]="previewUrl" *ngIf="previewUrl" alt="preview of uploaded image" class="image">
  </div>
  <div class="buttons">
    <app-danger-button [fullWidth]="true" (click)="fileUpload.click()">
      <span *ngIf="previewUrl else upload">
        {{'translate.operations.shared.uploadNewImage' | translate}}
      </span>
      <ng-template #upload>
        {{'translate.operations.shared.uploadImage' | translate}}
      </ng-template>
    </app-danger-button>
    <app-success-button [fullWidth]="true" [disabled]="!previewUrl || disableButton" (click)="nextStep()">{{'translate.form.next' | translate}}</app-success-button>
    <input (change)="onFileSelect($event)" #fileUpload type="file" hidden="hidden">
    <div class="text-center mt-4">
      <span class="skip" (click)="nextStep(true)">{{'translate.form.skip' | translate}}</span>
    </div>
  </div>

</div>

