
<div class="input">
  <p *ngIf="!hideLabel" class="label" [style.color]="darkMode ? 'white' : ''">{{label}}<span *ngIf="isRequired">*</span></p>
  <ng-container *ngIf="!multiLine else multi">
    <input class="form-field"
           [class.disabled]="disabled"
           [formControl]="control"
           [type]="type"
           [placeholder]="placeholder"
           (keyup)="handleKeyInput($event)"
           [ngClass]="{'invalid' : control.touched && control.errors}">
  </ng-container>
  <ng-template #multi>
    <textarea class="form-field"
              [class.disabled]="disabled"
              [style.height]="amountOfLines * 25 + 'px'"
              [formControl]="control"
              (keyup)="handleKeyInput($event)"
              [ngClass]="{'invalid' : control.touched && control.errors}">
    </textarea>
  </ng-template>
  <div *ngIf="control.errors">
    <span class="error-message-minimal" *ngIf="control.errors['required'] && control.touched">{{'translate.form.isRequired' | translate:{'item': label} }}</span>
  </div>
</div>
