import {Component, OnInit, ViewChild} from '@angular/core';
import {Observable} from 'rxjs';
import {DeviceDTO} from '../../../dtos/deviceDTO';
import {OperationService} from '../../../services/operation.service';
import {ScannedQR} from '../../../models/ScannedQR';
import {ScannerService} from '../../../services/scanner.service';
import {DeviceService} from '../../../services/device.service';
import {filter, tap} from 'rxjs/operators';
import {ConfirmActionModalComponent} from '../../plugins/confirm-action-modal/confirm-action-modal.component';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';

@Component({
  selector: 'app-create-device',
  templateUrl: './create-device.component.html',
  styleUrls: ['./create-device.component.scss', '../operations.scss']
})
export class CreateDeviceComponent implements OnInit {

  device$: Observable<DeviceDTO>;
  device: DeviceDTO;

  showScanner = false;
  badScan = false;

  @ViewChild('stepper') progressStepper;

  constructor(private operationService: OperationService,
              private scanService: ScannerService,
              private deviceService: DeviceService,
              private dialog: MatDialog,
              private router: Router) { }

  ngOnInit(): void {
    this.device$ = this.operationService.operationDevice$.pipe(
      tap(value => (value === undefined) ? this.router.navigate(['/operations']) : value));
  }

  scanCompleteHandler(event, device: DeviceDTO) {
    this.badScan = false;
    if (event === undefined) { this.badScan = true; return; }
    const scannedQR: ScannedQR = this.scanService.verifyScan(event);
    if (scannedQR == null) { this.badScan = true; return; }

    if (scannedQR.type === 'backplate' && device.backplate.backplateId === 0) {
      this.checkBackplateAvailability(scannedQR.backplateId, device);
    } else if (scannedQR.type === 'casing' && device.casingId === 0) {
      this.checkCasingAvailability(scannedQR.casingId, device);
    } else {
      this.badScan = true;
    }
  }

  changeCasing(device: DeviceDTO) {
    device.casingId = 0;
    this.progressStepper.return();
  }

  changeBackplate(device: DeviceDTO) {
    device.backplate.backplateId = 0;
    this.progressStepper.return();
  }

  createDevice(device: DeviceDTO) {
    this.deviceService.createDevice(device).pipe(
      filter(x => x !== undefined),
      tap(newDevice => {
        this.device = newDevice;
        this.progressStepper.continue();
      })
    ).subscribe();
  }

  goToCreateSmot() {
    this.operationService.operationDeviceChanged(this.device);
    this.router.navigate([`/operations/device/${this.device.deviceId}/create-smot`]);
  }

  goToOperations() {
    this.operationService.removeOperationDevice();
    this.router.navigate(['/operations']);
  }

  private checkCasingAvailability(id, device: DeviceDTO) {
    return this.deviceService.getDeviceByCasing(id).pipe(
      filter(x => x !== undefined),
      tap(result => {
        if (result === null) {
          device.casingId = +id;
          if (device.backplate.backplateId !== 0) { this.progressStepper.continue(); }
        } else {
          this.showConfirmActionModal('translate.operations.create-device.casingIsAlreadyUsedInAnotherDeviceDoYouWishToMoveItToThisDevice').pipe(
            filter(x => x !== undefined),
            tap(userResponse => {
              if (userResponse === true) {
                device.casingId = +id;
                if (device.backplate.backplateId !== 0) { this.progressStepper.continue(); }
              }
            })
          ).subscribe();
        }
      })
    ).subscribe();
  }


  private checkBackplateAvailability(id, device: DeviceDTO) {
    return this.deviceService.getDeviceByBackplate(id).pipe(
      filter(x => x !== undefined),
      tap(result => {
        if (result === null) {
          device.backplate.backplateId = +id;
          this.progressStepper.continue();
        } else {
            this.showConfirmActionModal('translate.operations.create-device.backplateIsAlreadyUsedInAnotherDeviceDoYouWishToMoveItToThisDevice').pipe(
            filter(x => x !== undefined),
            tap(userResponse => {
              if (userResponse === true) {
                device.backplate.backplateId = +id;
                this.progressStepper.continue();
              } else {
                this.progressStepper.return();
              }
            })
          ).subscribe();
        }
      })
    ).subscribe();
  }
  private showConfirmActionModal(message: String): Observable<any> {
    const dialog = this.dialog.open(ConfirmActionModalComponent, {
      data: {
        key: message
      }
    });
    return dialog.afterClosed();
  }
}
