<div class="sundo-container medium h-100 m-auto">
  <ng-container *ngIf="route$ | async as plannedRoute">
    <ng-container *ngIf="displayEnterLicensePlate">
      <app-h2 class="text-center">Voer de nummerplaat en kilometerstand van de auto die je zal gebruiken in</app-h2>
      <div class="buttons">
        <app-input-field [label]="'Kilometerstand'" [type]="'number'" [placeholder]="'999'" [control]="kilometerCountControl"></app-input-field>
        <app-input-field class="p-4" [label]="'Nummerplaat'" [placeholder]="'1-ABC-123'" [control]="licensePlateControl"></app-input-field>
        <app-success-button [fullWidth]="true" (click)="handleLicensePlateInput(plannedRoute)" >
          {{ 'translate.form.next' | translate}}
        </app-success-button>
      </div>
    </ng-container>
    <ng-container *ngIf="displayCheckNecessities">
      <app-h2 class="text-center">Controleer of je alles meehebt</app-h2>
        <ul *ngIf="maintenances$ | async as maintenances">
          <li *ngFor="let necessity of plannedRoute.route.necessities">
            <mat-checkbox required (change)="hasUncheckedValues(plannedRoute.route.necessities, maintenances)" [(ngModel)]="necessity.checked">
              {{ necessity.description }}
            </mat-checkbox>
          </li>
          <li *ngFor="let maintenance of maintenances">
            <mat-checkbox required (change)="hasUncheckedValues(plannedRoute.route.necessities, maintenances)" [(ngModel)]="maintenance.checked">
              {{ maintenance.description }}
            </mat-checkbox>
          </li>
        </ul>
        <div class="buttons">
          <app-success-button [fullWidth]="true" [disabled]="hasUncheckedNecessities" [class.disabled]="hasUncheckedNecessities" (click)="displayCheckNecessities = false" [type]="'submit'">
            {{ 'translate.form.next' | translate}}
          </app-success-button>
        </div>

    </ng-container>
    <ng-container *ngIf="!displayCheckNecessities && !displayEnterLicensePlate">
      <app-h2 class="text-center">{{plannedRoute.route.description}}</app-h2>
      <div class="route-steps hide-scrollbar p-4">
        <ng-container *ngIf="plannedRoute?.route?.steps as steps">
          <div class="step d-flex" *ngFor="let step of steps; let i = index">
            <div class="step-indicator mr-4" [ngClass]="step.started ? 'active' : ''">
              <div class="step-connect-line" [ngClass]="steps[i + 1]?.started ? 'active' : ''"></div>
            </div>
            <div class="step-content mb-4">
              <app-h4 [subtitle]="step.smot?.address ? step.smot.address : step.address">
                <ng-container *ngIf="step.smot?.locationDescription else content">
                  {{step.smot.locationDescription}}
                </ng-container>
                <ng-template #content>
                  {{step.content}}
                </ng-template>
              </app-h4>
              <p class="step-contact" *ngIf="step.contactPerson">
                <i class="cil-user"></i> {{ step.contactPerson }}
              </p>
              <div class="mt-1">
                <app-primary-button [size]="'small'" class="mr-3" *ngIf="nextStep?.routeStepId === step.routeStepId && step.smot" (click)="navigateWithWaze(step)"><img src="assets/img/brand/waze-icon.svg" alt="waze navigation app - icon" style="height: 24px"/></app-primary-button>
                <app-primary-button [size]="'small'" *ngIf="nextStep?.routeStepId === step.routeStepId && step.smot" (click)="navigateWithGoogleOrAppleMaps(step)"><i class="cil-map"></i></app-primary-button>
              </div>
              <ul class="pl-3">
                <ng-container *ngIf="step.smot?.locationDescription else stepComment">
                  <li class="step-comment" *ngFor="let comment of getInfoComments(step.smot?.routeStepComments)">
                    {{comment.content}}
                  </li>
                </ng-container>
                <ng-template #stepComment>
                  <li class="step-comment" *ngFor="let comment of getInfoComments(step.routeStepComments)">
                    {{comment.content}}
                  </li>
                </ng-template>
              </ul>
              <p class="skip" *ngIf="nextStep?.routeStepId === step.routeStepId" (click)="openConfirmSkipModal(step, plannedRoute)">{{'translate.form.skip' | translate}}</p>
            </div>
          </div>
        </ng-container>
      </div>
      <div class="buttons">
          <p class="text-center m-0 skip" (click)="this.displayEnterLicensePlate = true">{{'translate.general.edit' | translate}}</p>
          <p class="text-center license-plate m-auto" (click)="this.displayEnterLicensePlate = true">{{plannedRoute.licensePlate}}</p>
        <app-success-button [fullWidth]="true" (click)="startStep(plannedRoute)" >
          {{ getButtonText() | translate}}
        </app-success-button>
        <app-danger-button [fullWidth]="true" *ngIf="nextStep !== null" (click)="openFinishRouteModal(plannedRoute)">
          {{ 'translate.routes.finishRoute' | translate }}
        </app-danger-button>
      </div>
    </ng-container>
  </ng-container>
</div>
