import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SuccessButtonComponent} from './success-button/success-button.component';
import {WarningButtonComponent} from './warning-button/warning-button.component';
import {DangerButtonComponent} from './danger-button/danger-button.component';
import {PrimaryButtonComponent} from './primary-button/primary-button.component';


@NgModule({
  declarations: [
    PrimaryButtonComponent,
    DangerButtonComponent,
    WarningButtonComponent,
    SuccessButtonComponent,
  ],
  imports: [
    CommonModule
  ],
  exports: [
    PrimaryButtonComponent,
    DangerButtonComponent,
    WarningButtonComponent,
    SuccessButtonComponent,
  ]
})
export class SundoButtonsModule { }
