<div class="sundo-container medium m-auto h-100 position-relative">
  <div class="back-button" (click)="previousStep()">
    <mat-icon >keyboard_arrow_left</mat-icon>
  </div>
  <app-h2 class="text-center">{{'translate.routes.comments' | translate}}</app-h2>
  <app-h4 *ngIf="infoComments.length">Info</app-h4>
  <ul>
    <li *ngFor="let comment of infoComments">
      <mat-checkbox (change)="updateComment(comment, $event)" required [checked]="comment.resolutionStatus === 'RESOLVED' || comment.skippedComment">
      </mat-checkbox>
      <div>
        {{comment.content}}
        <ng-container *ngIf="!comment.skippedComment else reason">
          <span class="skip" (click)="skipComment(comment)">{{'translate.form.skip' | translate}}</span>
        </ng-container>
        <ng-template #reason>
          <app-input-field class="comment-input"
                           [hideLabel]="true"
                           [multiLine]="true"
                           [emitOnEveryKeyInput]="true"
                           (enteredInput)="comment.reason = $event"></app-input-field>
        </ng-template>
      </div>
    </li>
  </ul>
  <app-h4 *ngIf="comments.length">opmerkingen</app-h4>
  <ul>
    <li *ngFor="let comment of comments">
      <mat-checkbox (change)="updateComment(comment, $event)"  required [checked]="comment.resolutionStatus === 'RESOLVED' || comment.skippedComment">
      </mat-checkbox>
      <div>
        {{comment.content}}
        <ng-container *ngIf="!comment.skippedComment else reason">
          <span class="skip" (click)="skipComment(comment)">{{'translate.form.skip' | translate}}</span>
        </ng-container>
        <ng-template #reason>
          <app-input-field class="comment-input"
                           [hideLabel]="true"
                           [multiLine]="true"
                           [emitOnEveryKeyInput]="true"
                           (enteredInput)="comment.reason = $event"></app-input-field>
        </ng-template>
      </div>
    </li>
  </ul>
  <ng-container *ngIf="maintenance$ | async as maintenances">
      <app-h4 *ngIf="maintenances.length">Onderhoud</app-h4>
      <ul>
        <li *ngFor="let maintenance of maintenances">
          <mat-checkbox required [(ngModel)]="maintenance.checked" (change)="updateMaintenance(maintenance, $event)">
          </mat-checkbox>
          <div *ngIf="!maintenance.skipped else skipped">
            {{maintenance.description}}
            <span class="skip" (click)="skipMaintenance(maintenance)">{{'translate.form.skip' | translate}}</span>
          </div>
          <ng-template #skipped>
            <div>
              <s>{{maintenance.description}}</s>
              <span class="skip" (click)="skipMaintenance(maintenance)">{{'translate.form.skip' | translate}}</span>
            </div>
          </ng-template>
        </li>
      </ul>
    </ng-container>
  <div class="buttons">
    <app-success-button [fullWidth]="true" [disabled]="hasUnresolvedComments()" (click)="nextStep()">{{'translate.form.next' | translate}}</app-success-button>
  </div>
</div>
