import {Component, OnInit} from '@angular/core';
import {sideNavItems, topNavItems} from '../../_nav';
import {AuthenticationService} from '../../services/authentication.service';
import {Router} from '@angular/router';
import {Role} from '../../models/role';
import {TranslateService} from '@ngx-translate/core';
import {UserService} from '../../services/user.service';
import {ImageCreateService} from '../../services/image-create.service';
import {filter, tap} from 'rxjs/operators';
import {UserDTO} from '../../dtos/UserDTOs/userDTO';

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html'
})
export class DefaultLayoutComponent implements OnInit {
  public sidebarMinimized = false;
  public asidebarMinimized = false;
  public sideNavItems = null;
  public topNavItems = null;
  public username = 'undefined';
  public roleUser;
  public role = Role;
  isMobile: boolean = true;
  profileImage$;
  constructor(private auth: AuthenticationService,
              private router: Router,
              private ts: TranslateService,
              private userService: UserService,
              private authService: AuthenticationService,
              private imageCreateService: ImageCreateService) {
    this.username = this.auth.getUsername();
    this.roleUser = this.auth.getRole();
  }

  ngOnInit() {
    if (window.screen.width > 450) {
      this.isMobile = false;
    }



    this.userService.getUser<UserDTO>(this.username).pipe(
      filter(x => x !== undefined),
      tap(user => {
        this.setupNavigationBars(user);
        this.translateSideNavs();
        this.addOnLangChangeListener();
        this.handleProfileImage();
      })
    ).subscribe();
  }

  toggleMinimize(e) {
    this.sidebarMinimized = e;
  }

  private setupNavigationBars(user: UserDTO) {
    const nav = this.getRoleNavs();
    if (window.screen.width < 992) {
      nav.side = nav.side.concat(nav.top);
      nav.top = [];
    }

    if (!user.hasAccessToRoutes) {
      nav.side = nav.side.filter(item => item.name !== 'Routes');
    }

    this.sideNavItems = nav.side;
    this.topNavItems = nav.top;
  }

  toggleMinimizeAside(e) {
    this.asidebarMinimized = e;
  }

  logout() {
    this.auth.logout();
  }

  private getRoleNavs() {
    const side = sideNavItems.filter((item) => item.roles.includes(this.roleUser));
    const top = topNavItems.filter((item) => item.roles.includes(this.roleUser));
    return {side, top};
  }

  private translateSideNavs() {
    this.sideNavItems = this.sideNavItems.map(item => {
      item.name = this.ts.instant(item.key);
      item = Object.assign({}, item);
      return item;
    });
  }

  private addOnLangChangeListener() {
    this.ts.onLangChange.pipe(
      tap(() => this.translateSideNavs()),
    ).subscribe();
  }

  private handleProfileImage() {
    this.profileImage$ = this.imageCreateService.profileImage$;

    this.userService.fetchProfileImage(this.authService.getUsername()).pipe(
      tap(blob => this.imageCreateService.createProfileImg(blob))
    ).subscribe();
  }

}
