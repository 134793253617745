import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-progress-step',
  templateUrl: './progress-step.component.html',
  styleUrls: ['./progress-step.component.scss']
})
export class ProgressStepComponent implements OnInit {

  @Input() amountOfSteps;
  @Input() currentStep = 1;

  stepProgress: string = '0%';
  steps: Step[] = [];
  constructor() { }

  ngOnInit(): void {
    this.createSteps();
  }

  createSteps() {
    const steps = this.amountOfSteps - 1;
    for (let i = 0; i < this.amountOfSteps; i++) {
      const step: Step = {
        stepNumber: i + 1,
        progress: ((i / steps) * 100).toString() + '%'
      };

      this.steps.push(step);
    }

    this.stepProgress = this.steps[this.currentStep - 1]?.progress;
  }

  public return() {
    this.stepProgress = this.steps[this.currentStep - 1].progress;
    this.currentStep--;
  }

  public continue() {
    if (this.currentStep === this.amountOfSteps) {
      return;
    }
    this.stepProgress = this.steps[this.currentStep].progress;
    this.currentStep++;
  }
}

interface Step {
  stepNumber: number;
  progress: string;
}
