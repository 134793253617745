import {Component, OnInit, ViewChild} from '@angular/core';
import {EMPTY, Observable} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {catchError, filter, switchMap, tap} from 'rxjs/operators';
import {of} from 'rxjs/internal/observable/of';
import {OperationService} from '../../../services/operation.service';
import {SmotService} from '../../../services/smot.service';
import {ScannedQR} from '../../../models/ScannedQR';
import {ScannerService} from '../../../services/scanner.service';
import {ToastrNotificationService} from '../../../services/toastr-notification.service';
import {TranslateService} from '@ngx-translate/core';
import moment from 'moment';
import {FileUploadService} from '../../../services/file-upload.service';
import {MatDialog} from '@angular/material/dialog';
import {RouteService} from '../../../services/route.service';
import {CommentType} from '../../../models/commentType';
import {ResolutionStatus} from '../../../models/resolutionStatus';
import {SmotDTO} from '../../../dtos/SmotDTOs/smotDTO';
import {OperationDTO} from '../../../dtos/OperationDTOs/operationDTO';
import {RouteStepCommentDTO} from '../../../dtos/RouteDTOs/routeDTO';
import {InputModalComponent} from '../../plugins/input-modal/input-modal.component';

@Component({
  selector: 'app-smot-maintenance',
  templateUrl: './smot-maintenance.component.html',
  styleUrls: ['./smot-maintenance.component.scss', '../operations.scss']
})
export class SmotMaintenanceComponent implements OnInit {
  protected readonly CommentType = CommentType;
  smotId: string;
  smot$: Observable<SmotDTO>;

  comments: CommentWithCheckStatus[] = [];
  commentsSubmitted: boolean = false;


  disableButtons: boolean = false;
  refillSmot: boolean = false;
  badScan = false;
  info = {
    beforeUrl: '',
    afterUrl: ''
  };

  @ViewChild('stepper') progressStepper;

  constructor(private activatedRoute: ActivatedRoute,
              private operationService: OperationService,
              private routeService: RouteService,
              private smotService: SmotService,
              private scanService: ScannerService,
              private dialog: MatDialog,
              private toastr: ToastrNotificationService,
              private translate: TranslateService,
              private fileUpload: FileUploadService,
              private router: Router) {
    this.activatedRoute.paramMap.subscribe(params => this.smotId =  params.get('id'));
  }

  private static createRefillOperation(sunscreenId: number, smot: SmotDTO) {
    return {
      smot: smot,
      operationType: 'REFILL',
      info: JSON.stringify({
        newPackageId: sunscreenId,
        previousPackageId: smot.packaging?.packageId
      })
    } as OperationDTO;
  }

  ngOnInit(): void {
    this.smot$ = this.operationService.operationSmot$.pipe(
      switchMap(smot => {
        if (smot !== undefined) { return of(smot); }
        return this.smotService.getSmot(+this.smotId);
      }),
      filter(x => x !== undefined),
      tap(smot => this.mapSmotCommentsToCommentWithCheckStatus(smot.comments))
    );
  }

  // TODO: FIX: Currently you can enter invalid Packages, but it still returns with a success status
  handleSunscreenScanComplete(event, smot) {
    this.badScan = false;
    if (event === undefined) { this.badScan = true; return; }
    const scannedQR: ScannedQR = this.scanService.verifyScan(event);
    if (scannedQR == null) { this.badScan = true; return; }

    this.postOperation(SmotMaintenanceComponent.createRefillOperation(+scannedQR.sunscreenId, smot)).pipe(
      filter(x => x !== undefined),
      tap(() => this.progressStepper.continue())
    ).subscribe();
  }

  onFileSelect(event, type: 'before' | 'after', smot: SmotDTO) {
    const _this = this;
    const file = event.target.files[0];
    const reader = new FileReader();
    const currentDate = moment(Date.now()).format('YYYYMMDD');
    let imgName = '';

    this.progressStepper.continue();

    if (type === 'before') {
      this.info.beforeUrl = imgName = currentDate + '_' + smot.logicalId + '_before.png';
    }

    if (type === 'after') {
      this.info.afterUrl = imgName = currentDate + '_' + smot.logicalId + '_after.png';
    }

    reader.onload = function() {
      const img = new Image();

      img.src = reader.result as string;

      img.onload = function() {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        const imgDimensions = _this.calculateAspectRatioFit(img.width, img.height, 1080, 1080);

        canvas.width = imgDimensions.width;
        canvas.height = imgDimensions.height;

        ctx.drawImage(img, 0, 0, imgDimensions.width, imgDimensions.height);

        // Compress the image
        canvas.toBlob(function (blob) {
          _this.saveImage(blob, imgName);
        }, 'image/webp', 0.6);

      };
    };
    reader.readAsDataURL(file);
  }

  saveCleanOperation(smot) {
    this.disableButtons = true;
    this.operationService.postOperation(this.createCleanOperation(smot)).pipe(
      filter(x => x !== undefined),
      tap(() => this.router.navigate(['/operations'])),
      tap(() => this.toastr.showSucces('Maintenance Sucessfull', 'Succes')),
    ).subscribe();
  }

  submitCommentsForm() {
    this.handleSolvedComments();
    if (!this.checkIfAnyCommentsUnchecked()) {
      this.progressStepper.continue();
    } else {
      this.commentsSubmitted = true;
    }
  }

  displayAddCommentModal(smot: SmotDTO) {
    const dialog = this.dialog.open(InputModalComponent, {
      data: {
        key: 'translate.operations.smot-maintenance.addACommentToThisSMOTSpot',
        multiline: true
      }
    });
    dialog.afterClosed().pipe(
      filter(x => x !== undefined),
      switchMap(comment => {
        const commentDTO = {
          type: CommentType.COMMENT,
          smotLogicalId: smot.logicalId,
          content: comment,
          resolutionStatus: ResolutionStatus.UNRESOLVED,
          creationDate: new Date()
        } as RouteStepCommentDTO;

        return this.routeService.createComment(commentDTO).pipe(
          filter(x => x !== undefined)
        );
      })
    ).subscribe();
  }

  private postOperation(operation: OperationDTO) {
    return this.operationService.postOperation(operation)
      .pipe(
        filter(x => x !== undefined),
        tap(() => this.toastr.showSucces(this.translate.instant('translate.operations.shared.bagSuccesfullyReplaced'), this.translate.instant('translate.general.succes'))),
        catchError(error => {
          this.toastr.showErrorBasedOnStatus(error.status);
          return EMPTY;
        })
      );
  }

  private createCleanOperation(smot: SmotDTO) {
    return {
      smot: smot,
      operationType: 'CLEAN',
      info: JSON.stringify(this.info)
    } as OperationDTO;
  }

  private handleSolvedComments() {
    const solvedComments: RouteStepCommentDTO[] = this.comments.filter(c => c.solved)
      .map(c => ({
        ...c.comment,
    }) as RouteStepCommentDTO);
  }

  private mapSmotCommentsToCommentWithCheckStatus(comments: RouteStepCommentDTO[]) {
    if (!comments) { return; }
    comments = comments.filter(c => c.resolveDate === null || c?.type === CommentType.INFO);
    this.comments =  comments.map(c => ({
      solved: c.type === CommentType.INFO ? true : null,
      comment: c,
      type: c.type,
      reason: ''
    }) as CommentWithCheckStatus);
  }

  checkIfAnyCommentsUnchecked() {
    return this.comments.some(c => {
      return c.solved === null;
    });
  }

  handleUncheck(event, comment: CommentWithCheckStatus) {
    if (event.checked === true) {
      comment.solved = false;
    } else {
      comment.solved = null;
    }
  }

  private calculateAspectRatioFit(srcWidth, srcHeight, maxWidth, maxHeight) {
    const ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);
    return { width: srcWidth * ratio, height: srcHeight * ratio };
  }

  private saveImage(image, imgName) {
    this.fileUpload.uploadOperationsPicture(image, imgName).pipe(
      catchError(err => {
        this.toastr.showErrorBasedOnStatus(err.status);
        this.disableButtons = false;
        return EMPTY;
      })
    ).subscribe();
  }
}

interface CommentWithCheckStatus {
  comment: RouteStepCommentDTO;
  type: CommentType;
  solved: boolean;
  reason: string;
}
