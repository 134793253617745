<div class="d-flex flex-row justify-content-center align-items-center" style="height: 50vh;">
  <div *ngIf="!submitted" class="card" style="max-width: 350px">
    <div class="card-header">
      {{'translate.login.forgotPassword' | translate}}
    </div>
    <div class="container-text card-body">
      <app-h4 class="text-center">{{'translate.login.enterYourUsername' | translate}}</app-h4>
      <div class="d-flex justify-content-center">
        <form [formGroup]="forgotPassword" (ngSubmit)="sendForgotPassword();">
          <div class="w-100">
            <mat-form-field appearance="fill" >
              <mat-label>{{'translate.user.username' | translate}}</mat-label>
              <input type="text" placeholder="username" matInput [formControl]="username">
            </mat-form-field>
            <div *ngIf="username.errors?.required && username.touched" class="error-message-minimal" style="transform: translateY(-18px)">{{'translate.form.isRequired' | translate:{'item': 'translate.user.username' | translate } }}</div>
          </div>
          <div class="d-flex justify-content-center">
            <app-primary-button [disabled]="!forgotPassword.valid">{{'translate.form.submit' | translate}}</app-primary-button>
          </div>
        </form>
      </div>
    </div>
  </div>

  <div *ngIf="submitted" class="card" style="max-width: 350px">
    <div class="card-header">
      {{'translate.login.forgotPassword' | translate}}
    </div>
    <div class="container-text card-body">
      <app-h4>{{'translate.general.emailHasBeenSent' | translate}}</app-h4>
      <div class="d-flex justify-content-center">
        <app-primary-button [routerLink]="['/login']">{{'translate.form.back' | translate}}</app-primary-button>
      </div>
    </div>
  </div>
  </div>


