import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

// Import Containers
import {DefaultLayoutComponent} from './containers';

import {P404Component} from './views/error/404.component';
import {P500Component} from './views/error/500.component';
import {LoginComponent} from './views/login/login.component';
import {ChartfilterComponent} from './views/chartFilters/chartfilter.component';
import {OperationGuard} from './services/operation.guard';
import {ResetPasswordComponent} from './views/user/reset-password/reset-password.component';
import {ForgotPasswordComponent} from './views/user/forgot-password/forgot-password.component';
import {sideNavItems, topNavItems} from './_nav';
import {OverridesComponent} from './views/overrides/overrides.component';
import {AuthorizationGuard} from './services/guards/authorization.guard';

export const routes: Routes = [
  { path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full' },
  {
    path: '404',
    component: P404Component,
    data: {
      title: 'Page 404'
    }
  },
  {
    path: 'report-problem',
    loadChildren: () => import('./views/report-problem/report-problem.module').then(m => m.ReportProblemModule),
    data: {
      title: 'Report a problem'
    }
  },
  {
    path: '500',
    component: P500Component,
    data: {
      title: 'Page 500'
    }
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Login Page'
    }
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
    data: {
      title: 'Forgot Password'
    }
  },
  {
    path: 'reset-password/:token',
    component: ResetPasswordComponent,
    data: {
      title: 'Reset Password'
    }
  },
  {
    path: 'chartFilters',
    component: ChartfilterComponent,
    data: {
      title: 'Chartfilter'
    }
  },
  {
    path: '',
    canActivate: [],
    component: DefaultLayoutComponent,
    data: {
      title: 'Home'
    },
    children: [
      {
        path: 'base',
        canActivate: [AuthorizationGuard],
        loadChildren: () => import('./views/base/base.module').then(m => m.BaseModule)
      },
      {
        path: 'dashboard',
        canActivate: [AuthorizationGuard],
        data: {roles: sideNavItems.find(item => item.name === 'Dashboard').roles},
        loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'performance',
        canActivate: [AuthorizationGuard],
        data: {roles: sideNavItems.find(item => item.name === 'Performance').roles},
        loadChildren: () => import('./views/performance/performance.module').then(m => m.PerformanceModule)
      },
      {
        path: 'forms',
        canActivate: [AuthorizationGuard],
        loadChildren: () => import('./views/forms/forms.module').then(m => m.FormsModule)
      },
      {
        path: 'smotMap',
        canActivate: [AuthorizationGuard],
        data: {roles: sideNavItems.find(item => item.name === 'Map').roles},
        loadChildren: () => import('./views/dashboard-map/dashboard-map.module').then(m => m.DashboardMapModule)
      },
      {
        path: 'overrides',
        canActivate: [AuthorizationGuard],
        data: {roles: sideNavItems.find(item => item.name === 'Map').roles},
        component: OverridesComponent
      },
      {
        path: 'tables',
        canActivate: [AuthorizationGuard],
        loadChildren: () => import('./views/tables/tables.module').then(m => m.TablesModule)
      },
      {
        path: 'users',
        canActivate: [AuthorizationGuard],
        data: {roles: topNavItems.find(item => item.name === 'Users').roles},
        loadChildren: () => import('./views/user/user.module').then(m => m.UserModule)
      },
      {
        path: 'facilitators',
        canActivate: [AuthorizationGuard],
        data: {roles: topNavItems.find(item => item.name === 'Facilitators').roles},
        loadChildren: () => import('./views/facilitator/facilitator.module').then(m => m.FacilitatorModule)
      },
      {
        path: 'producers',
        canActivate: [AuthorizationGuard],
        data: {roles: topNavItems.find(item => item.name === 'Producers').roles},
        loadChildren: () => import('./views/producer/producer.module').then(m => m.ProducerModule)
      },
      {
        path: 'sunscreen',
        canActivate: [AuthorizationGuard],
        data: {roles: topNavItems.find(item => item.name === 'Sunscreen').roles},
        loadChildren: () => import('./views/sunscreen/sunscreen.module').then(m => m.SunscreenModule)
      },
      {
        path: 'smotspots',
        canActivate: [AuthorizationGuard],
        data: {roles: sideNavItems.find(item => item.name === 'SMOTSpots').roles},
        loadChildren: () => import('./views/smot/smot.module').then(m => m.SmotModule)
      },
      {
        path: 'admin',
        canActivate: [AuthorizationGuard],
        data: {roles: sideNavItems.find(item => item.name === 'Admin').roles},
        loadChildren: () => import('./views/admin/admin.module').then(m => m.AdminModule)
      },
      {
        path: 'profile',
        canActivate: [AuthorizationGuard],
        loadChildren: () => import('./views/profile/profile.module').then(m => m.ProfileModule)
      },
      {
        path: 'instructions',
        canActivate: [AuthorizationGuard],
        loadChildren: () => import('./views/instruction-videos/instruction.module').then(m => m.InstructionModule)
      },
      {
        path: 'settings',
        canActivate: [AuthorizationGuard],
        loadChildren: () => import('./views/settings/settings.module').then(m => m.SettingsModule)
      },
      {
        path: 'operations',
        canActivate: [OperationGuard],
        data: {roles: sideNavItems.find(item => item.name === 'Operations').roles},
        loadChildren: () => import('./views/operations-rework/operations.module').then(m => m.OperationsModule)
      },
      {
        path: 'route',
        canActivate: [AuthorizationGuard],
        data: {roles: sideNavItems.find(item => item.name === 'Routes').roles},
        loadChildren: () => import('./views/route/route.module').then(m => m.RouteModule)
      },
      {
        path: 'issue-overview',
        canActivate: [AuthorizationGuard],
        data: {roles: sideNavItems.find(item => item.name === 'Issues').roles},
        loadChildren: () => import('./views/issue-overview/issue-overview.module').then(m => m.IssueOverviewModule)
      },
      {
        path: 'issue-overview-filter-bar',
        canActivate: [AuthorizationGuard],
        data: {roles: sideNavItems.find(item => item.name === 'Issues').roles},
        loadChildren: () => import('./views/issue-overview/issue-overview.module').then(m => m.IssueOverviewModule)
      }
    ]
  },
  {path: '**', component: P404Component}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy', scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
