
<div class="d-flex justify-content-center">
  <div class="sundo-container medium">
    <div class="row d-flex justify-content-around p-4" [ngStyle]="{'min-width: 80vw; min-height: 80vh;': qrScanComplete}">
      <ng-container *ngIf="!qrScanComplete else overrides">
        <div id="scanner" style="max-width: 500px">
          <app-h3 class="text-center">{{'translate.operations.smot-scanner.scanAnyQRCode' | translate}}</app-h3>
          <div class="d-flex justify-content-center">
            <app-js-qr-scanner [badScan]="badScan" (codeScanned)="scanCompleteHandler($event)"></app-js-qr-scanner>
          </div>
        </div>
      </ng-container>
      <ng-template #overrides>
        <ng-container *ngIf="$device | async as device else loading">
          <app-h4 class="col-12 text-center" *ngIf="device.locationDescription">SMOTSpot: {{device.locationDescription}}</app-h4>
          <app-h4 class="col-12 text-center" *ngIf="device.backplate?.iccid">Iccid: {{device.backplate.iccid}}</app-h4>
          <app-h4 class="col-12 text-center" *ngIf="device.device?.backplate?.iccid">Iccid: {{device.device.backplate.iccid}}</app-h4>
          <app-warning-button [size]="'big'" (click)="sendOverride(device, '0')">{{'translate.overrides.noAction' | translate }}</app-warning-button>
          <app-warning-button [size]="'big'" (click)="sendOverride(device, '1')">{{'translate.overrides.sleep' | translate }}</app-warning-button>
          <app-warning-button [size]="'big'" (click)="sendOverride(device, '3')">{{'translate.overrides.refillNeeded' | translate }}</app-warning-button>
          <app-warning-button [size]="'big'" (click)="sendOverride(device, '4')">{{'translate.overrides.doorClosed' | translate }}</app-warning-button>
          <app-warning-button [size]="'big'" (click)="sendOverride(device, '15', false, true)">{{'translate.overrides.firmwareUpdateAvailable' | translate }}</app-warning-button>
          <app-warning-button [size]="'big'" (click)="sendOverride(device, '5')">{{'translate.overrides.mute' | translate }}</app-warning-button>
          <app-warning-button [size]="'big'" (click)="sendOverride(device, '13')">{{'translate.overrides.outOfOrder' | translate }}</app-warning-button>

          <app-h4 class="col-12 text-center">{{'translate.overrides.turnOnOff' | translate}}</app-h4>
          <app-warning-button [size]="'big'" (click)="sendOverride(device, '19')">{{'translate.overrides.turnOnDispenser' | translate }}</app-warning-button>
          <app-warning-button [size]="'big'" (click)="sendOverride(device, '9')">{{'translate.overrides.turnOffDispenser' | translate }}</app-warning-button>
          <app-warning-button [size]="'big'" (click)="sendOverride(device, '6')">{{'translate.overrides.turnLightOff' | translate }}</app-warning-button>
          <app-warning-button [size]="'big'" (click)="sendOverride(device, '7')">{{'translate.overrides.turnRfidOff' | translate }}</app-warning-button>

          <app-h4 class="col-12 text-center">{{'translate.overrides.resetReboot' | translate}}</app-h4>
          <app-warning-button [size]="'big'" (click)="sendOverride(device, '12')">{{'translate.overrides.setClock' | translate }}</app-warning-button>
          <app-warning-button [size]="'big'" (click)="sendOverride(device, '8')">{{'translate.overrides.resetScreen' | translate }}</app-warning-button>
          <app-warning-button [size]="'big'" (click)="sendOverride(device, '10')">{{'translate.overrides.softReset' | translate }}</app-warning-button>
          <app-warning-button [size]="'big'" (click)="sendOverride(device, '11')">{{'translate.overrides.factoryReset' | translate }}</app-warning-button>
          <app-warning-button [size]="'big'" (click)="sendOverride(device, '9')">{{'translate.overrides.reboot' | translate }}</app-warning-button>

          <app-h4  class="col-12 text-center">{{'translate.overrides.redownload' | translate}}</app-h4>
          <app-warning-button [size]="'big'" (click)="sendOverride(device, '14', false, true)">{{'translate.overrides.redownloadBrandLogo' | translate }}</app-warning-button>
          <app-warning-button [size]="'big'" (click)="sendOverride(device, '16', false, true)">{{'translate.overrides.redownloadSundoLogo' | translate }}</app-warning-button>
          <app-warning-button [size]="'big'" (click)="sendOverride(device, '17')">{{'translate.overrides.redownloadQrCode' | translate }}R</app-warning-button>

          <app-h4 class="col-12 text-center">{{'translate.overrides.override' | translate}}</app-h4>
          <app-warning-button [size]="'big'" (click)="sendOverride(device, '18')">{{'translate.overrides.overrideInitRecieved' | translate }}</app-warning-button>
          <app-warning-button [size]="'big'" (click)="sendOverride(device, '21', true)">{{'translate.overrides.overrideEventCounter' | translate }}</app-warning-button>
        </ng-container>
      </ng-template>
      <ng-template #loading>
          <mat-spinner [diameter]="100"></mat-spinner>
      </ng-template>
    </div>
  </div>
</div>
