import {Component, OnInit} from '@angular/core';
import {AuthenticationService} from '../../../../services/authentication.service';
import {EMPTY, Observable} from 'rxjs';
import {RouteService} from '../../../../services/route.service';
import {PlannedRouteMappings} from '../../../../models/dtoMappings';
import moment from 'moment/moment';
import {catchError, filter, map} from 'rxjs/operators';
import {TablePlannedRouteDTO} from '../../../../dtos/RouteDTOs/tablePlannedRouteDTO';
import {ToastrNotificationService} from '../../../../services/toastr-notification.service';

@Component({
  selector: 'app-start-route',
  templateUrl: './start-route.component.html',
  styleUrls: ['./start-route.component.scss', '../../operations.scss']
})
export class StartRouteComponent implements OnInit {
  username: string = '';
  plannedRoutes$: Observable<TablePlannedRouteDTO[]>;

  constructor(private authService: AuthenticationService,
              private routeService: RouteService,
              private toastr: ToastrNotificationService) { }

  ngOnInit(): void {
    this.username = this.authService.getUsername();
    this.plannedRoutes$ = this.fetchPlannedRoutes();
  }

  private fetchPlannedRoutes() {
    return this.routeService.getPlannedRoutes<TablePlannedRouteDTO[]>(PlannedRouteMappings.TABLE_PLANNED_ROUTE_DTO, moment().startOf('day').unix(), this.username).pipe(
      filter(x => x !== undefined),
      map(routes => routes.filter(r => !r.finishTime)),
      catchError(err => {
        this.toastr.showErrorBasedOnStatus(err.status);
        return EMPTY;
      })
    );
  }
}
