import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import jwtDecode from 'jwt-decode';
import {UserService} from '../../../services/user.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ToastrNotificationService} from '../../../services/toastr-notification.service';
import {catchError, filter, tap} from 'rxjs/operators';
import {EMPTY} from 'rxjs';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  username: string;
  token: string;
  form: FormGroup;
  password: FormControl = new FormControl('', Validators.required);
  confirmPassword: FormControl = new FormControl('', [Validators.required]);

  constructor(private route: ActivatedRoute,
              private userService: UserService,
              private toastr: ToastrNotificationService,
              private router: Router) {
    this.createForm();
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      this.token = params.get('token');
      this.decodeToken();
    });
  }

  decodeToken() {
    const decodedToken: any = jwtDecode(this.token);
    this.username = decodedToken.sub;

  }


  createForm() {
    this.form = new FormGroup({
      password: this.password,
      confirmPassword: this.confirmPassword,
    });
  }

  changePassword() {
    if (this.form.invalid) {
      return;
    }

    if (this.password.value !== this.confirmPassword.value) {
      return;
    }

    this.userService.resetPassword(this.password.value, this.username, this.token).pipe(
      filter(x => x !== undefined),
      tap(() => this.router.navigate(['login'])),
      catchError(err => {
        this.toastr.showErrorBasedOnStatus(err.status);
        this.router.navigate(['login']);
        return EMPTY;
      })).subscribe();
  }
}
