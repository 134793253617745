import {Component, Input, OnInit} from '@angular/core';
import {BaseRouteStepComponent} from '../base-route-step/base-route-step.component';
import {RouteService} from '../../../../services/route.service';
import {filter, tap} from 'rxjs/operators';
import {RouteFlowStepData} from '../../../../models/routeFlows';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-route-video',
  templateUrl: './route-video.component.html',
  styleUrls: ['./route-video.component.scss', '../../operations.scss']
})
export class RouteVideoComponent implements OnInit, BaseRouteStepComponent {
  @Input() data: RouteFlowStepData;
  imageDataURLs = [];
  currentImgDataURL = '';
  currentImgIndex = 0;
  displayImages: boolean = false;
  videoURL;

  constructor(private routeService: RouteService,
              private sanitizer: DomSanitizer) {
  }

  ngOnInit(): void {
    this.sanitizeURL();
    this.getRouteStepImages();
  }

  public nextImage() {
    if (this.currentImgIndex === this.imageDataURLs.length - 1) {
      return;
    }

    this.currentImgIndex++;
    this.currentImgDataURL = this.imageDataURLs[this.currentImgIndex].dataURL;
  }

  public previousImage() {
    if (this.currentImgIndex === 0) {
      return;
    }
    this.currentImgIndex--;
    this.currentImgDataURL = this.imageDataURLs[this.currentImgIndex].dataURL;
  }

  public previousStep() {
    this.routeService.emitOnStepReturn();
  }

  public nextStep() {
    this.routeService.emitOnStepCompleted();
  }

  private sanitizeURL() {
    this.videoURL = this.sanitizer.bypassSecurityTrustResourceUrl(this.data?.videoUrl);
  }

  private getRouteStepImages() {
    this.routeService.getRouteStepImages(this.data.plannedRoute.route.routeId, this.data.stepId).pipe(
      filter(x => x !== undefined),
      tap((images: any[]) => images.forEach(image => {
        this.imageDataURLs.push(image);
      })),
      tap(() => {
        if (this.imageDataURLs.length) {
          this.displayImages = true;
          this.currentImgDataURL = this.imageDataURLs[this.currentImgIndex].dataURL;
        }
      })
    ).subscribe();
  }

}
